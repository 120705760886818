import { BackgroundBlock } from "../../components/ui";

function AdministrationPage() {
	const title = "Администрация";

	const administration = [
		{
			post: "Начальник ГКУЗ ЛО БСМЭ",
			name: "Зайцева Марина Александровна",
			phone: "88122513124",
			displayedPhone: "8 (812) 252-31-24",
		},
		{
			post: "Заместитель начальника по организационно-методической работе",
			name: "Костюченко Константин Игоревич",
			phone: "88122523124",
			displayedPhone: "8 (812) 252-31-24",
		},
		{
			post: "Заместитель начальника по экспертной работе",
			name: "Моисеенко Сергей Анатольевич",
			phone: "88122523124",
			displayedPhone: "8 (812) 252-31-24",
		},
		{
			post: "Заместитель начальника по экономическим вопросам",
			name: "Лебедь Оксана Витальевна",
			phone: "88122514449",
			displayedPhone: "8 (812) 251-44-49",
		},
		{
			post: "Заместитель начальника по административно-хозяйственным вопросам",
			name: "Андруш Екатерина Геннадьевна",
			phone: "88124000734",
			displayedPhone: "8 (812) 400-07-34",
		},
		{
			post: "Главный бухгалтер",
			name: "Воронкова Татьяна Николаевна",
			phone: "88122521472",
			displayedPhone: "8 (812) 252-14-72",
		},
		{
			post: "Начальник отдела кадров",
			name: "Нефедова Елена Владимировна",
			phone: "88122512600",
			displayedPhone: "8 (812) 251-26-00",
		},
		{
			post: "Начальник отдела размещения государственного заказа",
			name: "Подосинникова Екатерина Андреевна",
			phone: "88122525345",
			displayedPhone: "8 (812) 252-53-45",
		},
		{
			post: "Ведущий юрисконсульт",
			name: "Румянцев Станислав Геннадьевич",
			phone: "88126172876",
			displayedPhone: "8 (812) 617-28-76",
		},
		{
			post: "Специалист по охране труда",
			name: "Окунева Кристина Вадимовна",
			phone: "88126187883",
			displayedPhone: "8 (812) 618-78-83",
		},
		{
			post: "Специалист гражданской обороны",
			name: "Телегин Роман Владимирович",
			phone: "88124000716",
			displayedPhone: "8 (812) 400-07-16",
		},
	];

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock
				title={ title }
				links={
					[
						{
							href: "/administrativno-upravvlencheskij-personal",
							name: "Административно-управленческий персонал"
						}
					]
				}
			/>
			<div className={`px-4 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex flex-col gap-4`}>
				<div className="flex flex-col gap-16">
					{
						administration.map(el =>
							<div
								key={ `administration-${ administration.indexOf(el) }` }
								className="flex flex-col gap-4"
							>
								<div className="text-justify font-bold text-lg">
									{ el.post }
								</div>
								<div className="text-justify font-bold text-xl uppercase">
									{ el.name }
								</div>
								{
									el.phone &&
									<div>
										Телефон: <a className='font-bold hover:underline'
										            href={ `tel:${ el.phone }` }>
										{ el.displayedPhone }
									</a>
									</div>
								}
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
}

export default AdministrationPage;